import { defineStore } from "pinia";
import { apiBackendAuthAxios } from "@/axiosAuth";
import { useGlobalStore } from "./global";
import { usePostStore } from "./post";
import { useUserStore } from "./users";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    accessToken: ``,
    refreshToken: ``,
    authenticated: false,
    scopes: [],
    currentUser: null,
    fcmToken: ``,
  }),
  getters: {
    // isAPIExample: state => state.scopes.includes(`api_example`)
  },
  actions: {
    async login(credentials) {
      const response = await fetch("/api/login/google", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      if (response.ok) {
        const { token } = await response.json();
        this.token = token;
        this.authenticated = true;
        if (this.token && this.currentUser) {
          const redirect =
            this.$router.currentRoute.value.query.redirect || "/feed"; // Get the redirect URL or default to "/feed"
          if (!this.currentUser.accountSetup) {
            this.$router.push("/setup");
          } else if (!this.currentUser.acceptTermsOfService) {
            this.$router.push("/policy");
          } else {
            this.$router.push(redirect); // Redirect to the intended URL
          }
        }
      } else {
        throw new Error("Failed to login");
      }
    },
    getAccessToken() {
      return this.accessToken;
    },
    setAuthenticated(tokenData) {
      if (tokenData) {
        this.authenticated = true;
        this.accessToken = tokenData.access_token;
        this.refreshToken = tokenData.refresh_token;
        return true;
      } else {
        console.error("No access token provided");
        return false;
      }
    },

    async setUser() {
      const globalStore = useGlobalStore();

      try {
        const response = await apiBackendAuthAxios.get("/user");

        this.currentUser = response.data.data;

        globalStore.fetchSettings();
        if (globalStore.badgeTypes.length === 0) {
          globalStore.fetchBadgeTypes();
        }
        if (globalStore.notificationTypes.length === 0) {
          globalStore.getNotificationTypes();
        }
        if (globalStore.reactionTypes.length === 0) {
          globalStore.fetchReactionTypes();
        }
        if (
          globalStore &&
          this.currentUser.following < 5 &&
          globalStore.isParler
        ) {
          globalStore.updateSettings("default_feed", "influencers");
          globalStore.currentFeed = "influencers";
        }

        return this.currentUser; // Return the user data
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        if (error.response && error.response.status === 401) {
          this.setUnauthenticated(this.$router);
        }
        throw error; // Re-throw the error to be handled by the caller
      }
    },

    async refreshUser() {
      try {
        const response = await apiBackendAuthAxios.get("/user");

        this.currentUser = response.data.data;

        return this.currentUser; // Return the user data
      } catch (error) {
        console.error("Failed to fetch user data:", error);

        throw error; // Re-throw the error to be handled by the caller
      }
    },

    async signOut(router) {
      try {
        // If we have an fcmToken, we need to logout on the backend
        if (this.fcmToken) {
          const endpoint = `https://fcm.googleapis.com/fcm/send/${this.fcmToken}`;
          let response = await apiBackendAuthAxios.post("/user/logout", {
            endpoint: endpoint,
          });
        }
      } catch (error) {
        console.error("Error logout: ", error);
      }
      // Clear the user data
      this.setUnauthenticated(router);

      // Clear FCM token
      this.clearFcmToken();
    },

    // Set FCM TOKEN
    setFcmToken(token) {
      this.fcmToken = token;
    },
    // Clear FCM TOKEN
    clearFcmToken() {
      this.fcmToken = null;
    },

    setUnauthenticated(router) {
      const postStore = usePostStore();
      const userStore = useUserStore();
      const globalStore = useGlobalStore();

      try {
        // Clear IndexedDB
        postStore.deletePostDb();
        userStore.deleteUserDb();
      } catch (error) {
        console.error("Failed to clear IndexedDB:", error);
      }

      // Clear stores
      postStore.$reset();
      userStore.$reset();
      globalStore.$reset();
      this.$reset();

      // Clear reactive state
      this.authenticated = false;
      this.accessToken = "";
      this.refreshToken = "";
      this.currentUser = null;
      this.scopes = [];

      // Clear localStorage
      localStorage.clear();

      // Clear session storage
      sessionStorage.clear();

      // Redirect to the login page
      router.push("/login").then(() => {
        // Reload the window to ensure all state and data are cleared
        window.location.reload();
      });
    },

    clearUserData() {
      this.currentUser = null;
    },
  },
  persist: true,
});
